import React from 'react';
import styled from '@lib/styled';
import Header from '@atoms/Header';
import Footer from '@atoms/Footer';
import Announcements from '@containers/Announcements';
import { useRouter } from 'next/router';
type WrapperProps = {
  [name: string]: any;
};

const SuperContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100vh;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 60vh;
  padding: 15px;
  max-width: 900px;
  margin: auto;
  position: relative;
  padding-top: 60px;
`;

const showAnnouncementsLookup = {
  faq: true,
  volunteer: true
};

const showAnnouncements = firstCrumb => {
  return showAnnouncementsLookup[firstCrumb];
};

export const Wrapper = (props: WrapperProps) => {
  const router = useRouter();
  if (props.embedded) {
    return (
      <>
        <main {...props} />
      </>
    );
  }
  const crumbs = router.asPath.split('/');
  const crumb = crumbs.length && crumbs[1];
  return (
    <SuperContainer>
      {showAnnouncements(crumb) && <Announcements />}
      <Header />
      <Container>
        <main {...props} />
      </Container>
      <Footer />
    </SuperContainer>
  );
};
